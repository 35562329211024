// source -> https://github.com/uidotdev/usehooks/blob/dfa6623fcc2dcad3b466def4e0495b3f38af962b/index.js#L1310
// docs -> https://usehooks.com/usewindowscroll

import { useCallback, useState } from 'react';
import { useIsomorphicLayoutEffect } from '@global-ecom/nitro-uds/hooks';

type ScrollPosition = {
  x: number | null;
  y: number | null;
};

type ScrollTo_Params = [x: number, y: number] | ScrollToOptions; // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo

export const useWindowScroll = (): [
  scrollPosition: ScrollPosition,
  scrollTo: (params: ScrollTo_Params) => void
] => {
  const [state, setState] = useState<ScrollPosition>({
    x: null,
    y: null,
  });

  const scrollTo = useCallback((...args) => {
    if (typeof args[0] === 'object') {
      window.scrollTo(args[0]);
    } else if (typeof args[0] === 'number' && typeof args[1] === 'number') {
      window.scrollTo(args[0], args[1]);
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    const handleScroll = () => {
      setState({ x: window.scrollX, y: window.scrollY });
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [state, scrollTo];
};

// usage: `const [{ x, y }, scrollTo] = useWindowScroll();`
