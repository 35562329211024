import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { css, tw } from 'twind/style';
import { Stack } from '@global-ecom/nitro-uds/elements';
import { Scrollbar } from 'react-scrollbars-custom';

import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
import {
  Cta as CtaType,
  LinkVariant,
  Maybe,
  ProductCarousel as ProductCarouselType,
  RecommendationsOutput,
  useVariantsQuery,
  Variant,
  VariantProduct,
} from '__generated__/graphql';
import { RecommendationTile } from 'ui/components/RecommendationTile';
import { GridTemplateColumnConfig } from 'ui/content/ProductRecommender';
import { CallToActions } from 'ui/content/CallToActions';
import { getIsDesktop, getIsMobile } from 'utils/media';
import { usePromotionView, GaTrackData } from 'hooks/usePromotionView';
import { useFeature } from 'hooks/useFeature';
import ChevronButton from 'ui/components/ChevronButton';
import { useIsCrawler } from 'hooks/useIsCrawler';
import { ProductObjectType as SanityProduct } from 'groq/global-types';
import { usePdp } from 'hooks/usePdp';
import {
  event,
  AnalyticsEvents,
  transformListProductToAnalyticsItem,
  handleIntersectionCarousel,
} from 'utils/analytics';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useProductClickHandling } from 'hooks/useProductClickHandling';
import { usePageEventsContext } from 'hooks/usePageEventsContext';
import { ItemProductInterface } from 'types/analyticTypes';
import { Skeleton } from 'ui/components/Skeleton';
import { useIsServer } from 'hooks/useIsServer';

import { ContentHeading, ManageHeadingTag } from './ContentHeading';

const isSanityProduct = (product: any): product is SanityProduct =>
  product._type === 'product';

const transformSanityProduct = (product: SanityProduct) => ({
  id: product.masterId + '_' + product.color,
  masterId: product.masterId,
  variantId: product.productId,
  name: product.productName,
});

export type ProductCarouselProps = Omit<ProductCarouselType, 'products'> & {
  _id?: string;
  _type?: string;
  campaignId?: string;
  cta?: Maybe<CtaType[]>;
  gridTemplateColumnConfig?: GridTemplateColumnConfig;
  inSideBySide?: boolean;
  products: Array<VariantProduct | Variant> | Array<SanityProduct>;
  recommendationsKOP: { id: string | undefined; name: string | undefined };
  fromRecommender?: Pick<
    RecommendationsOutput,
    'recommenderId' | 'recommenderName'
  >;
  stackProductInfo?: boolean;
  isMiniCart?: boolean;
};

export const DEFAULT_MOBILE_PRODUCT_GRID_SIZE =
  'repeat(@{itemCount}, calc(50% - 1rem))';

export const DEFAULT_PRODUCT_GRID_SIZE =
  'repeat(@{itemCount}, calc(50% - 3rem))';

const DEFAULT_SKELETONS = 5;

/**
 * Transforms a given grid template into `gridTemplateColumn` Tailwind classes for desktop, tablet, and mobile.
 * Instances of the `'@{itemCount}'` placeholder are replaced with the actual number of items in the carousel.
 * The provided templates should be in the following format: `'repeat(@{itemCount}, calc(50% - 3rem))'`.
 * `'calc(50% - 3rem)'` indicates that 2 items are to be displayed with a 3rem peek of the subsequent item.
 * @param template - The grid template to transform.
 * @param itemCount - The number of items in the carousel.
 */
const transformGridColumnTemplate = (
  template: GridTemplateColumnConfig,
  itemCount: number
) => {
  const replaceItemCount = (str = DEFAULT_PRODUCT_GRID_SIZE) =>
    str.replaceAll('@{itemCount}', itemCount.toString());

  const gridTemplateColumns = {
    desktop: { gridTemplateColumns: replaceItemCount(template.desktop) },
    tablet: { gridTemplateColumns: replaceItemCount(template.tablet) },
    mobile: { gridTemplateColumns: replaceItemCount(template.mobile) },
  };

  return {
    desktop: css(gridTemplateColumns.desktop),
    tablet: css(gridTemplateColumns.tablet),
    mobile: css(gridTemplateColumns.mobile),
  };
};

export const ProductCarousel = ({
  cta,
  gridTemplateColumnConfig,
  header,
  inSideBySide,
  products,
  recommendationsKOP,
  fromRecommender,
  stackProductInfo,
  isMiniCart,
  headingTag,
  ...props
}: ProductCarouselProps & { headingTag?: ManageHeadingTag }) => {
  // it's possible that the data from sanity contains duplicate products, and so
  // we need to ensure we de-duplicate these
  const isServer = useIsServer();
  const styleNumbers: string[] = [];
  const uniqueProducts: (VariantProduct | Variant | SanityProduct)[] = [];
  const { pageviewEventHasFired } = usePageEventsContext();
  const isCrawler = useIsCrawler();

  for (const product of products) {
    const colorValue = isSanityProduct(product)
      ? product.color
      : product.colorValue || /* istanbul ignore next */ product.color;

    const styleNumber = `${product.masterId}_${colorValue}`;

    // already seen this style number; ignore this product
    if (styleNumbers.includes(styleNumber)) continue;

    styleNumbers.push(styleNumber);
    uniqueProducts.push(product);
  }

  const [variantsResult] = useVariantsQuery({
    variables: {
      input: {
        styleNumberIds: styleNumbers,
      },
    },
    pause: products.length === 0 || (isServer && !isCrawler),
  });

  const { ref, hasPrev, hasNext, next, prev } = useHorizontalScroll({
    infinite: false,
  });

  // whilst the variants are loading, we'll use the products from Sanity to
  // display a skeleton loader for each item
  const items =
    variantsResult.data?.variants ??
    uniqueProducts.map(product =>
      isSanityProduct(product) ? transformSanityProduct(product) : product
    );

  const isDesktop = getIsDesktop();
  const isMobile = getIsMobile();

  const showAllPrices = useFeature('SHOW_ALL_PRICES_AT_ONCE');
  const isSpecificPriceLayout = stackProductInfo || showAllPrices;

  const ctaLinkProps = {
    variant: LinkVariant.White,
  };

  const gridClasses = (length: number) =>
    tw([
      'grid gap-2 desktop:gap-4 pb-8 mb-4 md:overflow-x-auto',
      gridTemplateColumnConfig
        ? transformGridColumnTemplate(gridTemplateColumnConfig, length)
        : transformGridColumnTemplate(
            {
              mobile: DEFAULT_MOBILE_PRODUCT_GRID_SIZE,
              tablet: DEFAULT_PRODUCT_GRID_SIZE,
              desktop: 'repeat(@{itemCount}, calc(25% - 2rem))',
            },
            length
          ),
    ]);

  const productInfoConfig = {
    containerClassName: `mobile:flex-col tablet:flex-col desktop:${
      isSpecificPriceLayout ? 'flex-col' : 'flex-row'
    }`,
    priceContainerClassName: tw(
      `mobile:items-start tablet:items-start desktop:${
        isSpecificPriceLayout ? 'items-start' : 'items-end'
      }`,
      stackProductInfo && 'text-sm'
    ),
    productTitleContainerClassName: tw([
      stackProductInfo && 'text-sm',
      showAllPrices && 'mb-4',
    ]),
  };

  const { currency } = useSiteConfig();
  const pdp = usePdp();

  const itemListId = props._id || fromRecommender?.recommenderId || '';
  const itemListName = props._type || fromRecommender?.recommenderName || '';

  const listItems = useMemo(() => {
    if (!variantsResult.data?.variants || variantsResult.fetching) return [];
    return variantsResult.data?.variants.map((product, index) => {
      return transformListProductToAnalyticsItem({
        product,
        currency: currency.code,
        quantity: 1,
        categories: {
          item_category: undefined,
        },
        itemListId,
        itemListName,
        index,
      });
    });
  }, [
    variantsResult.fetching,
    variantsResult.data?.variants,
    currency.code,
    itemListId,
    itemListName,
  ]);

  const gaTrackData: GaTrackData = {
    id: props.puid?.current || fromRecommender?.recommenderName || '',
    name: header || '',
    creative: 'product-carousel',
    campaign: props.campaignId || '',
    position: '',
  };

  const { promotionTrackerPosition } = usePromotionView(
    ref,
    gaTrackData,
    false,
    true
  );

  // To differentiate in which carousel the click occurred.
  const setIsProductClicked = useProductClickHandling();

  if (promotionTrackerPosition) {
    gaTrackData.position = promotionTrackerPosition;
  }
  const [indexItems, setIndexItems] = useState(0);
  const scrollerTriggerEvent = entry => {
    if (entry === 'left') {
      if (indexItems > 0) setIndexItems(indexItems - 1);
      prev();
      if (pageviewEventHasFired)
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.THUMBNAIL_ARROW,
          event_params: {
            user_action: 'left click',
            item_id_ep: recommendationsKOP?.id,
            item_name_ep: recommendationsKOP?.name,
          },
        });
    } else if (entry === 'right') {
      if (indexItems < items.length) setIndexItems(indexItems + 1);
      next();
      if (pageviewEventHasFired)
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.THUMBNAIL_ARROW,
          event_params: {
            user_action: 'right click',
            item_id_ep: recommendationsKOP?.id,
            item_name_ep: recommendationsKOP?.name,
          },
        });
    }
  };

  useEffect(() => {
    if (variantsResult.data?.variants)
      ref?.current?.dispatchEvent(new Event('scroll'));
  }, [variantsResult.data?.variants, ref]);

  const intersectedProductsRef = useRef<ItemProductInterface[]>([]);
  const intersectAllProductsRef = useRef<ItemProductInterface[]>([]);
  const countProductsTriggeredRef = useRef<number>(0);
  const screenSizeCarousel = isDesktop ? 4 : 2;
  const itemEpId = pdp?.masterProduct?.id || '';
  const itemEpName = pdp?.masterProduct?.name || '';

  const handleIntersection = useCallback(
    (productId: string) => {
      handleIntersectionCarousel({
        productId,
        intersectedProductsRef,
        intersectAllProductsRef,
        pageviewEventHasFired,
        itemEpId,
        itemEpName,
        itemListId,
        itemListName,
        screenSizeCarousel,
        listProducts: listItems,
        countProductsTriggeredRef,
      });
    },
    [
      itemEpId,
      itemEpName,
      itemListId,
      itemListName,
      listItems,
      pageviewEventHasFired,
      screenSizeCarousel,
    ]
  );

  useEffect(() => {
    if (!ref.current) return;

    const options = {
      root: null,
      rootMargin: '1px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const productId = entry.target.getAttribute('data-product-id');
          if (productId) handleIntersection(productId);
        }
      });
    }, options);

    const itemsToObserve = ref.current.querySelectorAll(
      '[data-test-id="recommended-product-item"]'
    );
    itemsToObserve.forEach(item => {
      observer.observe(item);
    });

    return () => {
      itemsToObserve.forEach(item => {
        observer.unobserve(item);
      });
      observer.disconnect();
    };
  }, [handleIntersection, ref]);

  return (
    <section
      className="group relative w-full"
      aria-label={header || undefined}
      data-test-id="recommendations"
      onClick={() =>
        isMiniCart &&
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.MINICART,
          event_params: {
            user_action: 'recommended product',
          },
        })
      }
    >
      {((!variantsResult.fetching && items.length > 0) ||
        variantsResult.fetching) &&
        header && (
          <ContentHeading
            header={header}
            className={tw(
              'font-bold pb-4 text-uppercase flex justify-between',
              'text-lg sm:text-2xl',
              inSideBySide && 'leading-5 items-center'
            )}
            headingTag={headingTag}
            dataTestId="recommend-header-text"
          >
            {cta ? (
              <CallToActions
                className={`whitespace-nowrap font-normal pr-0 text-${
                  isMobile ? 'xs' : 'sm'
                }`}
                ctas={cta}
                linkProps={ctaLinkProps}
                gaBannerData={gaTrackData}
              />
            ) : (
              <div style={{ width: '1em', height: '37px' }} />
            )}
          </ContentHeading>
        )}
      {items.length ? (
        <>
          <Scrollbar
            translateContentSizeYToHolder
            removeTracksWhenNotUsed
            disableTracksWidthCompensation
            disableTrackYMousewheelScrolling
            trackXProps={{
              renderer: props => {
                const { elementRef, style, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    className="TrackX"
                    style={{ ...style, height: 6, left: 0, width: '100%' }}
                  />
                );
              },
            }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, style, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    className="MyAwesomeScrollbarsScroller" // Default library className
                    style={{
                      ...style,
                      scrollSnapType: 'x mandatory',
                      scrollBehavior: 'smooth',
                    }}
                  />
                );
              },
            }}
            contentProps={{
              renderer: props => {
                const { elementRef, style, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    className="Content pb-4"
                    style={{ ...style, display: 'block' }}
                  />
                );
              },
            }}
            thumbXProps={{
              renderer: props => {
                const { elementRef, style, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    className="ThUmBX"
                    style={{ ...style, background: 'rgba(0, 0, 0, 0.65)' }}
                  />
                );
              },
            }}
          >
            <div
              ref={ref}
              data-test-id="recommendation-product-carousel"
              className={gridClasses(items.length)}
              onClick={() => setIsProductClicked(true)}
            >
              {items.map((item, idx) => (
                <RecommendationTile
                  key={item.id}
                  containerClassName="flex-shrink-0 w-full"
                  dataTestId="recommended-product-item"
                  linkClassName="flex w-full relative"
                  fromRecommender={fromRecommender}
                  product={item}
                  productInfoConfig={productInfoConfig}
                  gaBannerData={gaTrackData}
                  fetching={variantsResult.fetching}
                  isFromMiniCart={isMiniCart}
                  itemListId={props._id}
                  itemListName={props._type}
                  position={idx}
                />
              ))}
            </div>
          </Scrollbar>
          {hasPrev && isDesktop && (
            <ChevronButton
              className="invisible group-hover:visible mobile:hidden tablet:hidden absolute -left-2 top-1/2 transform -translate-y-1/2"
              direction="left"
              onClick={() => scrollerTriggerEvent('left')}
              invert
            />
          )}
          {hasNext && isDesktop && (
            <ChevronButton
              className="invisible group-hover:visible mobile:hidden tablet:hidden absolute -right-2 top-1/2 transform -translate-y-1/2"
              direction="right"
              onClick={() => scrollerTriggerEvent('right')}
              invert
            />
          )}
        </>
      ) : (
        variantsResult.fetching && (
          <Stack className={gridClasses(DEFAULT_SKELETONS)}>
            {Array.from({ length: DEFAULT_SKELETONS }, (_, i) => i + 1).map(
              (_, idx) => (
                <Skeleton
                  className="w-full aspect-1-1"
                  key={`skeleton-${idx}`}
                />
              )
            )}
          </Stack>
        )
      )}
    </section>
  );
};
