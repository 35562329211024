import { tw } from 'twind/css';
import React, { useRef } from 'react';
import { useRouter } from 'next/router';

import { Ga4Data } from 'hooks/usePromotionSelect';
import { Link } from 'ui/elements/Link';
import {
  FeaturesGrid as FeaturesGridType,
  FeatureGridRow as FeatureGridRowType,
} from '__generated__/graphql';
import { CallToActions } from 'ui/content/CallToActions';
import { SanityImage } from 'ui/components/SanityImage';
import { Markdown } from 'ui/elements/Markdown';
import { SLOT_LINK_TYPE } from 'utils/constants';
import { BannerLinkType } from 'utils/types';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';

import { LinkOrTag, TagType } from './LinkOrTag';
import { ContentHeading, ManageHeadingTag } from './ContentHeading';

const smallImageStyles =
  'relative pt-72 flex-grow hidden lg:col-span-2 lg:block';
const largeImageStyles = 'relative pt-72 flex-grow md:col-span-4';

const FeatureGridRow: React.FC<
  FeatureGridRowType & {
    headingTag?: ManageHeadingTag;
    priority?: boolean;
  }
> = ({
  layout,
  primaryImage,
  mobilePrimaryImage,
  secondaryImage,
  ctas,
  header,
  headingTag,
  copy,
  id,
  __typename,
  priority,
}) => {
  const { asPath } = useRouter();
  const promotionId = id;
  const promotionName = header || '';
  const creativeName = __typename || 'FeaturesGridRow';

  const featureGridRowRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: promotionId,
    name: promotionName,
    creative: creativeName,
  };

  const extendedGaTrackData = {
    ...gaTrackData,
    cta_links: ctas && ctas.length > 0 ? ctas.map(c => c.link) : [],
  };

  const { ga4PromotionTrackerPosition } = usePromotionView(
    featureGridRowRef,
    extendedGaTrackData,
    true
  );

  const ga4Data: Ga4Data = {
    creative_name: creativeName,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id: promotionId,
    promotion_name: promotionName,
    link_url: ctas && ctas.length > 0 ? ctas[0].link : '',
    cta_click: undefined,
    fireEventFromPdp: asPath.includes('/pd/'),
  };
  return (
    <div
      className={`relative flex ${
        layout === 'left' ? 'flex-col-reverse' : 'flex-col'
      } md:grid md:grid-cols-8`}
      ref={featureGridRowRef}
    >
      {layout === 'left' ? (
        secondaryImage && (
          <div className={smallImageStyles}>
            <SanityImage source={secondaryImage} priority={priority} />
          </div>
        )
      ) : (
        <div className={largeImageStyles}>
          <SanityImage
            source={primaryImage}
            mobileSource={mobilePrimaryImage}
            priority={priority}
          />
        </div>
      )}
      {ctas && ctas[0] && (
        <Link
          query={ctas[0].query}
          newtab={!!ctas[0].newtab}
          href={ctas[0].link}
          className="absolute inset-0 h-full w-full"
          aria-hidden
          tabIndex={-1}
          ga4Data={ga4Data}
        />
      )}
      <div
        className={`relative flex flex-col items-center justify-center pb-2 p-4 md:pb-7 text-center text-puma-black md:col-span-4 ${
          secondaryImage ? 'lg:col-span-2' : ''
        }`}
      >
        {header && (
          <ContentHeading
            header={header}
            className={tw('font-bold text-xl')}
            headingTag={headingTag}
          />
        )}
        {copy && (
          <>
            <Markdown content={copy} />
          </>
        )}
        <div className="flex flex-wrap justify-center flex-gap-3 lg:flex-gap-4 mt-4">
          <CallToActions ctas={ctas} ga4Data={ga4Data} />
        </div>
      </div>
      {layout === 'left' ? (
        <div className={largeImageStyles}>
          <SanityImage
            source={primaryImage}
            mobileSource={mobilePrimaryImage}
            priority={priority}
          />
        </div>
      ) : (
        secondaryImage && (
          <div className={smallImageStyles}>
            <SanityImage source={secondaryImage} priority={priority} />
          </div>
        )
      )}
    </div>
  );
};

export const FeaturesGrid: React.FC<
  FeaturesGridType & { _id?: string; link?: BannerLinkType }
> = props => {
  const { asPath } = useRouter();
  const featuresGridRef = useRef(null);

  const creative_name = props.__typename || 'FeaturesGrid';
  const promotion_id = props._id || '';

  const gaTrackData: GaTrackData = {
    id: props.id,
    name: '',
    creative: creative_name,
    promotion_id,
  };

  const { ga4PromotionTrackerPosition } = usePromotionView(
    featuresGridRef,
    gaTrackData,
    true
  );

  const ga4Data: Ga4Data = {
    creative_name: creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name: '',
    link_url: props.link?.link || '',
    fireEventFromPdp: asPath.includes('/pd/'),
  };

  return (
    <LinkOrTag
      href={props.link?.link}
      linkType={SLOT_LINK_TYPE.LINK}
      className="space-y-4 md:space-y-0"
      ga4Data={ga4Data}
      tag={TagType.SECTION}
      ref={featuresGridRef}
    >
      {props.featureRows.map(featureRow => (
        <FeatureGridRow key={featureRow.id} {...featureRow} />
      ))}
    </LinkOrTag>
  );
};
