import {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { StyliticsLook, useOutfitsQuery } from '__generated__/graphql';
import { StyliticsCarouselDocumentType } from 'groq/documents/StyliticsCarouselDocument';
import { TileWithPositionDocumentType } from 'groq/objects/TileWithPosition';
import { StyliticsSanityProvider } from 'hooks/useStyliticsSanity';
import { Ga4Data } from 'hooks/usePromotionSelect';
import StyliticsCarouselContainer from 'ui/components/StyliticsSanity/StyliticsCarouselContainer';
import StyliticsGridContainer from 'ui/components/StyliticsSanity/StyliticsGridContainer';
import StyliticsHeader from 'ui/components/StyliticsSanity/StyliticsHeader';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { useIsServer } from 'hooks/useIsServer';
import { useIsCrawler } from 'hooks/useIsCrawler';

export type StyliticsTiles = (TileWithPositionDocumentType | StyliticsLook)[];

// Consts
const MIN_OUTFITS = 2;
const MAX_OUTFITS = 25;

type StyliticsSanityProps = {
  content: StyliticsCarouselDocumentType;
  outfits: StyliticsLook[];
  ga4Data?: Ga4Data;
  styliticsRef?: MutableRefObject<null>;
};

const StyliticsSanity = ({
  content,
  outfits,
  ga4Data,
  styliticsRef,
}: StyliticsSanityProps) => {
  const {
    tiles: contentTiles,
    numberOfTiles,
    tileLayout = 'carousel',
    header,
    tags,
  } = content;

  const calculateTiles = useCallback(() => {
    if (!outfits) return [];

    const display: StyliticsTiles = [...outfits];
    let tileCount = numberOfTiles ?? MAX_OUTFITS;

    // If no tiles in Sanity -> display just outfits but only the max qty configured
    if (!contentTiles) {
      return display.slice(0, tileCount);
    }

    // Display tiles with outfits in the position specified in Sanity
    const newTiles = [...display];
    contentTiles.forEach(t => {
      // This actual position is derived because We don't want the sanity indices to be treated as 0-based.
      const actualPosition = t.position - 1;
      if (actualPosition < outfits.length) {
        newTiles.splice(actualPosition, 0, t);
      }
    });

    const promoTilesCount = display.filter(
      tile =>
        (tile as TileWithPositionDocumentType)._type === 'tileWithPosition'
    ).length;

    if (numberOfTiles) tileCount = numberOfTiles - promoTilesCount;

    return newTiles.slice(0, tileCount);
  }, [outfits, contentTiles, numberOfTiles]);

  const [tiles, setTiles] = useState<StyliticsTiles>(calculateTiles);

  useEffect(() => {
    return setTiles(calculateTiles());
  }, [calculateTiles]);

  const isCarousel = tileLayout === 'carousel';

  return (
    <div className="max-w-full min-h-[700px]" ref={styliticsRef}>
      <div className="p-[30px]">
        <StyliticsHeader header={header} />
        <StyliticsSanityProvider
          isCarousel={isCarousel}
          tags={tags}
          tiles={tiles}
          ga4Data={ga4Data}
        >
          {isCarousel && <StyliticsCarouselContainer />}
          {!isCarousel && <StyliticsGridContainer />}
        </StyliticsSanityProvider>
      </div>
    </div>
  );
};

export const StyliticsCarousel = (props: StyliticsCarouselDocumentType) => {
  const isCrawler = useIsCrawler();
  const isServer = useIsServer();

  const promotion_id = props._id || '';
  const promotion_name = props.header || '';
  const creative_name = props._type || 'StyliticsCarousel';
  const styliticsSanityRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: promotion_id,
    name: promotion_name,
    creative: creative_name,
  };
  const { ga4PromotionTrackerPosition } = usePromotionView(
    styliticsSanityRef,
    gaTrackData,
    true
  );

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    link_url: undefined,
    cta_click: undefined,
  };

  const hasTags = props.tags && props.tags.length > 0;

  const [results] = useOutfitsQuery({
    pause: !hasTags || (isServer && !isCrawler),
    variables: {
      tags: props.tags?.join(',') || '',
    },
  });

  const outfits = results.data?.outfits;

  if (!outfits || outfits.length < MIN_OUTFITS) return null;

  return (
    <StyliticsSanity
      content={props}
      outfits={outfits as StyliticsLook[]}
      ga4Data={ga4Data}
      styliticsRef={styliticsSanityRef}
    />
  );
};
