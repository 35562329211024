import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Alert } from '@global-ecom/nitro-uds/elements';
import { tw } from 'twind/css';
import BlockContent from '@sanity/block-content-to-react';

import { SignUpForm, useCreateContactMutation } from '__generated__/graphql';
import { extractError } from 'utils/extractError';
import { Markdown } from 'ui/elements/Markdown';
import { SanityImage } from 'ui/components/SanityImage';
import { CallToActions } from 'ui/content/CallToActions';
import { Container } from 'ui/elements/LayoutContainer';
import { emailSignUpEvent } from 'utils/analytics';
import { EmailSignUpActions } from 'types/analyticTypes';
import { useInView } from 'hooks/useInView';
import { useToast } from 'hooks/useToast';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { serializeTocBlockContent } from 'utils/serializeBlockContent';
import { useSiteConfig } from 'hooks/useSiteConfig';

import {
  createContactInput,
  SignUpFormField,
  SignupFormFields,
} from './SignUpFormField';
import { ContentHeading, ManageHeadingTag } from './ContentHeading';

// See SignUpForm.tsx for DataLayer
export const SignUpHeroBanner: React.FC<
  SignUpForm & {
    formBackgroundImage?: SignUpForm['backgroundImage'];
    _id?: string;
    _type?: string;
    headingTag?: ManageHeadingTag;
  }
> = props => {
  const inverted = !!props.inverted;
  const [createContactResult, createContact] = useCreateContactMutation();
  const { countryCode } = useSiteConfig();

  const submittedSuccessfully =
    createContactResult.data &&
    !createContactResult.error &&
    !createContactResult.fetching;

  const openToast = useToast();

  const [signUpFormHeroInViewRef, signUpFormHeroInView] = useInView();

  const gaTrackData: GaTrackData = {
    id: props._id || '',
    name: props.header || '',
    creative: props._type || '',
  };

  usePromotionView(signUpFormHeroInViewRef, gaTrackData, true);

  useEffect(() => {
    if (submittedSuccessfully) {
      openToast({
        variant: 'success',
        title: props.successHeader,
        description: props.successCopy,
      });
    }
  }, [
    submittedSuccessfully,
    openToast,
    props.successCopy,
    props.successHeader,
  ]);

  useEffect(() => {
    if (signUpFormHeroInView) {
      emailSignUpEvent(EmailSignUpActions.Impression, 'SignUpFormHero');
    }
  }, [signUpFormHeroInView]);

  const form = useForm<SignupFormFields>({
    defaultValues: {},
    shouldUnregister: false,
    mode: 'onChange',
  });

  const submitForm = async (data: SignupFormFields) => {
    const contact = createContactInput(data, props.contactSource, countryCode);
    const result = await createContact({
      input: {
        contact,
      },
    });

    emailSignUpEvent(
      result.error ? EmailSignUpActions.Error : EmailSignUpActions.Submit,
      'SignUpFormHero'
    );

    return result;
  };

  useEffect(() => {
    if (
      form.formState.isSubmitted &&
      Object.keys(form.formState.errors).length
    ) {
      emailSignUpEvent(EmailSignUpActions.Error, 'SignUpFormHero');
    }
  }, [form.formState]);

  const details: React.ComponentProps<typeof SignUpFormField>['details'] = {
    ctaLabel: props.submitButtonText,
  };

  return (
    <Container bleed data-test-id="sign-up-form-hero-banner">
      <section
        className={tw(
          'relative overflow-hidden',
          props.backgroundColor
            ? `bg-[${props.backgroundColor}]`
            : inverted
            ? 'bg-black'
            : 'bg-white',
          inverted ? 'text-white' : 'text-puma-black'
        )}
        ref={signUpFormHeroInViewRef}
      >
        <div>
          <SanityImage
            source={props.formBackgroundImage}
            mobileSource={props.mobileBackgroundImage}
          />
        </div>
        <div className="relative flex flex-col text-center items-center px-8 py-6 md:px-10 md:py-10 lg:px-16 lg:py-12 space-y-12">
          <div>
            {props.header && (
              <ContentHeading
                header={props.header}
                className="text-3xl xs:text-4xl md:text-5xl font-bold font-display"
                headingTag={props.headingTag}
              />
            )}
            {props.copy && (
              <p className="text-base xs:text-xl md:text-2xl mt-2">
                <Markdown content={props.copy} />
              </p>
            )}
            {props.footnote && (
              <p className="text-xs mt-1 md:text-sm">
                <Markdown content={props.footnote} />
              </p>
            )}
            <div className="relative mt-6 flex flex-col space-y-3 max-w-64 mx-auto mt-8">
              <CallToActions
                linkProps={{ variant: inverted ? 'white' : 'black' }}
                ctas={props.ctas}
              />
            </div>
          </div>
          <hr className="w-full border-t border-neutral-50" />
          <form
            className="flex w-full flex-col max-w-3xl mt-4 justify-center space-y-6"
            onSubmit={form.handleSubmit(data => submitForm(data))}
          >
            {extractError(createContactResult) && (
              <Alert
                title="Error"
                variant="error"
                content={extractError(createContactResult) as string}
              />
            )}
            {props.fields.length > 1 ? (
              <>
                <div
                  className={tw(
                    'grid grid-cols-1 gap-2 md:gap-4',
                    props.fields.length > 1 && 'sm:grid-cols-2'
                  )}
                >
                  {props.fields.map(field => {
                    if (field === 'name')
                      return ['firstName', 'lastName'].map(name => (
                        <div key={name} className="w-full">
                          <SignUpFormField
                            inverted={inverted}
                            form={form}
                            field={name}
                            isLoading={createContactResult.fetching}
                            details={details}
                          />
                        </div>
                      ));
                    else if (field === 'address')
                      return ['address', 'city', 'zip-code'].map(name => (
                        <div key={name} className="w-full">
                          <SignUpFormField
                            inverted={inverted}
                            form={form}
                            field={name}
                            isLoading={createContactResult.fetching}
                            details={details}
                          />
                        </div>
                      ));
                    else if (field === 'ticketChoice')
                      return (
                        <div
                          key={field}
                          className="w-full flex flex-col sm:flex-row sm:col-span-2 sm:justify-around"
                        >
                          <h1 className="flex flex-col justify-center">
                            {props.customText}
                          </h1>
                          {['montreal', 'toronto'].map(name => (
                            <SignUpFormField
                              inverted={inverted}
                              key={name}
                              form={form}
                              field={name}
                              isLoading={createContactResult.fetching}
                              details={details}
                            />
                          ))}
                        </div>
                      );
                    else
                      return (
                        <div
                          key={field}
                          className={tw(
                            (field === 'opt-out' ||
                              field === 'ageCheck' ||
                              field === 'emailPreference') &&
                              'flex justify-start mt-4 col-span-full'
                          )}
                        >
                          <SignUpFormField
                            inverted={inverted}
                            form={form}
                            field={field}
                            isLoading={createContactResult.fetching}
                            details={details}
                          />
                        </div>
                      );
                  })}
                </div>
                {props.portableDisclaimerText && (
                  <BlockContent
                    id={'sign-up-form-newsletter-disclaimer-text'}
                    blocks={props.portableDisclaimerText}
                    serializers={serializeTocBlockContent}
                    className="w-full"
                  />
                )}
                <div className="flex justify-center">
                  <Button
                    label={props.submitButtonText}
                    type="submit"
                    size="lg"
                    className="w-full max-w-72"
                    invert={inverted}
                  />
                </div>
              </>
            ) : (
              <div className="flex flex-col sm:flex-row gap-2 md:gap-4">
                <div className="flex-grow">
                  <SignUpFormField
                    inverted={inverted}
                    form={form}
                    field={props.fields[0]}
                    isLoading={createContactResult.fetching}
                    details={details}
                  />
                </div>
                <Button
                  label={props.submitButtonText}
                  type="submit"
                  size="lg"
                  className="w-full max-w-72"
                  invert={inverted}
                />
              </div>
            )}
          </form>
        </div>
      </section>
    </Container>
  );
};
