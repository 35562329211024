import React, { useRef } from 'react';
import BlockContent from '@sanity/block-content-to-react';

import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { serializeTocBlockContent } from 'utils/serializeBlockContent';
import { AdditionalContentSectionDocumentType } from 'groq/documents/AdditionalContentSectionDocument';

export function AdditionalContentSection({
  _id,
  _type,
  text,
  localeText,
}: AdditionalContentSectionDocumentType) {
  const additionalContentSectionRef = useRef(null);
  const promotionId = _id || '';
  const promotionName = '';
  const creativeName = _type || 'AdditionalContentSection';
  const gaTrackData: GaTrackData = {
    id: promotionId,
    name: promotionName,
    creative: creativeName,
  };
  usePromotionView(additionalContentSectionRef, gaTrackData, true);

  if (!localeText && !text) return null;

  return (
    <div
      data-test-id="additional-content-section"
      ref={additionalContentSectionRef}
    >
      <BlockContent
        id={_id + '-additional-content'}
        blocks={localeText || text}
        serializers={serializeTocBlockContent}
        className="w-full"
      />
    </div>
  );
}
