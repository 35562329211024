import { useRef } from 'react';
import { tw } from 'twind';
import { useRouter } from 'next/router';

import {
  HorizontalAlignment,
  SplitHero as SplitHeroType,
} from '__generated__/graphql';
import { SanityImage } from 'ui/components/SanityImage';
import { CallToActions } from 'ui/content/CallToActions';
import { Markdown } from 'ui/elements/Markdown';
import { alignmentToFlexDirection } from 'utils/alignmentToFlexDirection';
import { Container } from 'ui/elements/LayoutContainer';
import { usePromotionView } from 'hooks/usePromotionView';
import { CountdownClock } from 'ui/content/CountdownClock';
import { slotLink } from 'utils/urls';
import { LinkType, SLOT_LINK_TYPE } from 'utils/constants';
import { BannerLinkType } from 'utils/types';
import { Ga4Data } from 'hooks/usePromotionSelect';

import { ContentHeading, ManageHeadingTag } from './ContentHeading';
import { LinkOrTag, TagType } from './LinkOrTag';

type SplitHeroProps = SplitHeroType & {
  headingTag: ManageHeadingTag;
  campaignId?: string;
  linkType?: LinkType;
  link: BannerLinkType;
  priority?: boolean;
};

export const SplitHero = ({
  backgroundColor,
  backgroundImage,
  campaignId = '',
  copy,
  countdownClock,
  ctas = [],
  linkType,
  link,
  footnote,
  header = '',
  horizontalAlignment = 'center',
  headingTag,
  layout,
  logo,
  mobileBackgroundImage,
  puid,
  textColor,
  id,
  __typename,
  priority,
}: SplitHeroProps) => {
  const { asPath } = useRouter();

  const hAlign = alignmentToFlexDirection(
    horizontalAlignment as HorizontalAlignment
  );

  const hasContent = header || copy || footnote || (ctas && ctas.length > 0);

  const imageDimensions = backgroundImage?.asset?.metadata?.dimensions;
  const mobileImageDimensions =
    mobileBackgroundImage?.asset?.metadata?.dimensions;

  const splitHeroRef = useRef(null);

  const promotion_name = header || '';

  const gaTrackData = {
    id: puid?.current || '',
    name: promotion_name,
    creative: 'split-hero',
    campaign: campaignId,
    position: '',
  };

  const navigateTo = slotLink(linkType, ctas, link);

  const promotion_id = id;
  const creative_name = __typename || 'SplitHero';

  const extendedGaTrackData = {
    ...gaTrackData,
    promotion_id,
    creative_name,
    cta_links: ctas && ctas.length > 0 ? ctas.map(c => c.link) : [],
  };

  const { promotionTrackerPosition, ga4PromotionTrackerPosition } =
    usePromotionView(splitHeroRef, extendedGaTrackData);

  if (promotionTrackerPosition) {
    gaTrackData.position = promotionTrackerPosition;
  }

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    link_url: navigateTo?.link || '',
    fireEventFromPdp: asPath.includes('/pd/'),
  };

  return (
    <Container bleed>
      <LinkOrTag
        dataLinkLoc="banner"
        className={tw(
          `relative flex mobile:flex-col`,
          layout === 'left' ? 'flex-row-reverse' : 'flex-row'
        )}
        ref={splitHeroRef}
        query={navigateTo?.query}
        newtab={!!navigateTo?.newtab}
        href={navigateTo?.link}
        gaBannerData={gaTrackData}
        ga4Data={ga4Data}
        linkType={linkType!}
        tag={TagType.ARTICLE}
      >
        <div
          className={`relative w-full aspect-${
            mobileImageDimensions?.width || imageDimensions?.width
          }-${
            mobileImageDimensions?.height || imageDimensions?.height
          } md:aspect-${imageDimensions?.width}-${imageDimensions?.height}`}
        >
          <SanityImage
            source={backgroundImage}
            mobileSource={mobileBackgroundImage}
            priority={priority}
          />
        </div>
        {hasContent && (
          <div
            className={`w-full tablet:w-2/4 desktop:w-2/5 flex-none flex-col items-center p-8 pt-6 tablet:p-10 desktop:p-16 text-[${textColor}] bg-[${backgroundColor}]`}
          >
            <div className="w-full">
              {logo?.asset?.url && (
                <SanityImage
                  className="h-14 w-max mobile:m-auto"
                  source={logo}
                  relative
                  priority={priority}
                />
              )}
            </div>
            <div
              className={`relative w-full h-full flex flex-col items-${hAlign} justify-center mobile:items-center`}
            >
              <div className={`text-${horizontalAlignment} mobile:text-center`}>
                {header && (
                  <ContentHeading
                    header={header}
                    className={
                      'mobile:text-3xl tablet:text-4xl desktop:text-5xl font-bold font-display leading-tight'
                    }
                    headingTag={headingTag}
                  />
                )}
                {copy && (
                  <h2 className="text-base desktop:text-2xl leading-tight">
                    <Markdown content={copy} />
                  </h2>
                )}
                {footnote && (
                  <h3 className="mobile:text-xs tablet:text-sm desktop:text-base mt-1">
                    <Markdown content={footnote} />
                  </h3>
                )}
              </div>
              {countdownClock && (
                <CountdownClock
                  {...countdownClock}
                  config={{
                    showLabels: true,
                    labelClassName:
                      'text-xs desktop:text-sm uppercase desktop:font-bold',
                    timeUnitClassName: 'text-[32px] xl:text-[56px] font-normal',
                    containerClassName: 'py-6 space-x-8 xl:space-x-12',
                  }}
                />
              )}
              {linkType === SLOT_LINK_TYPE.CTA && (
                <div
                  className={`flex flex-wrap justify-${hAlign} mobile:justify-center flex-gap-3 md:flex-gap-4`}
                >
                  <CallToActions
                    ctas={ctas}
                    gaBannerData={gaTrackData}
                    ga4Data={{
                      ...ga4Data,
                      link_url: ctas && ctas.length > 0 ? ctas[0].link : '',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </LinkOrTag>
    </Container>
  );
};
