import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import {
  pageview,
  sessionCartTotalEvent,
  toAnalyticsCart,
} from 'utils/analytics';
import { AnalyticsPageType } from 'types/analyticTypes';
import { useMeQuery } from '__generated__/graphql';

import { useAuth } from './useAuth';
import { useCart } from './useCart';
import { useFeature } from './useFeature';
import { usePageEventsContext } from './usePageEventsContext';
import { useSiteConfig } from './useSiteConfig';

/**
 * Used to register a pageview analytic event for the current page.
 * @param fetching Set to false (default) if the document title does not rely on dynamic data
 */

const usePageview = (
  fetching = false,
  pageTypeOverride?: AnalyticsPageType
) => {
  const {
    setPageviewEventHasFired,
    pageviewEventHasFired,
    userConsent,
    cartEventFired,
    setCartEventFired,
  } = usePageEventsContext();
  const [prevPath, setPrevPath] = useState<string | null>(null);
  const router = useRouter();
  const {
    countryCode,
    language,
    env: { oneTrustApiKey },
  } = useSiteConfig();
  const { customerId, guest } = useAuth();

  const { cart } = useCart();
  const [meResult] = useMeQuery({
    pause: guest,
  });

  const showCookieConsentLayer = useFeature('SHOW_COOKIE_CONSENT_LAYER');
  const triggerCartTotalEvent = useFeature('TRIGGER_CART_TOTAL_EVENT');

  const _cookieConsentApiKey = oneTrustApiKey ?? '';
  // We should validate the user consent to if we have OT api key and show the banner
  const validateWithUserConsent = !(
    !_cookieConsentApiKey || !showCookieConsentLayer
  );

  useEffect(() => {
    const splittedPath = router.asPath.split('?')[0];
    const path = router.asPath.includes('&') ? splittedPath : router.asPath;
    const customerEmail = meResult.data?.me?.email;
    const customerNum = meResult.data?.me?.customerNo;

    const analyticsData = {
      pageTitle: document.title,
      searchQuery: router.query.searchQuery as string,
      country: countryCode,
      language: language,
      customerId: customerId ?? '',
      customerAuth: !guest,
      customerEmail: customerEmail || undefined,
      customerNo: customerNum || undefined,
      cart: toAnalyticsCart(cart),
      currentURL: document.URL,
    };

    if (
      !fetching &&
      (cart || cart === null) &&
      !cartEventFired &&
      triggerCartTotalEvent &&
      pageviewEventHasFired
    ) {
      sessionCartTotalEvent(cart?.subTotal);
      setCartEventFired(true);
    }
    // Ensure the pageview does not get registered twice by comparing the current path to the pageview path
    if (!guest && !customerEmail) return;
    if (validateWithUserConsent) {
      if (!fetching && path !== prevPath && customerId && userConsent) {
        pageview(router.asPath, analyticsData, pageTypeOverride);
        setPageviewEventHasFired(true);
        setPrevPath(path);
      }
    } else if (!fetching && path !== prevPath && customerId) {
      pageview(router.asPath, analyticsData, pageTypeOverride);
      setPageviewEventHasFired(true);
      setPrevPath(path);
    }
  }, [
    cart,
    countryCode,
    customerId,
    fetching,
    guest,
    language,
    pageTypeOverride,
    prevPath,
    router.asPath,
    router.query.searchQuery,
    setPageviewEventHasFired,
    userConsent,
    validateWithUserConsent,
    cartEventFired,
    setCartEventFired,
    triggerCartTotalEvent,
    meResult.data?.me?.email,
    meResult.data?.me?.customerNo,
    pageviewEventHasFired,
  ]);
};

export default usePageview;
