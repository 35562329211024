import {
  ComponentWithFilters,
  ProductComparison,
  AutomatedProductComparison,
} from '__generated__/graphql-sanity';

// This function processes a given content slot and ensures that only one
//  product comparison remains. Additionally, it verifies that the retained
// product comparison includes more than one visible product.

const hasMultipleVisibleProducts = (
  products?:
    | ProductComparison['products']
    | AutomatedProductComparison['products']
): boolean => {
  return (products?.filter(product => product?.visible).length || 0) >= 2;
};

export const filterProductComparison = (data: ComponentWithFilters[]) => {
  const comparisonTypes = new Set([
    'ProductComparison',
    'AutomatedProductComparison',
  ]);
  let hasAddedComparison = false;

  const filteredStory = data?.filter(slot => {
    const componentType = slot.component?._type;

    const isComparisonType = comparisonTypes.has(componentType!);
    if (!isComparisonType) return true; // Keep non-comparison components

    const component = slot.component as
      | ProductComparison
      | AutomatedProductComparison;

    if (!hasAddedComparison && hasMultipleVisibleProducts(component.products)) {
      hasAddedComparison = true;
      return true;
    }

    return false;
  });

  return filteredStory;
};
