import React from 'react';
import { tw } from 'twind/style';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import {
  ProductMeasurements,
  useCategorySizeChartQuery,
  useProductSizeChartQuery,
} from '__generated__/graphql';
import Modal from 'ui/components/Modal';
import { LoadingSpinner } from 'ui/components/LoadingSpinner';
import { SafeHtml } from 'ui/elements/SafeHtml';

import CloseButton from '../CloseButton';

import { ProductMeasurementsTable } from './ProductMeasurementsTable';
import { sizeGuideStyles } from './shared';

type SizeGuideProps = {
  sizeChartId?: string | null;
  categoryId?: string | null;
  productMeasurements?: ProductMeasurements | null;
  onClickOutside: () => void;
  productDivision?: string | null;
};

export const SizeGuide = ({
  sizeChartId,
  categoryId,
  productMeasurements,
  onClickOutside,
  productDivision,
}: SizeGuideProps) => {
  const t = useTranslate();

  const {
    staticFeatures: { useProductSizeChart, useDefaultJapanChart },
  } = useSiteConfig();

  const [categorySizeChartQuery] = useCategorySizeChartQuery({
    variables: { id: categoryId! },
    pause: !categoryId,
  });

  const [productSizeChartQuery] = useProductSizeChartQuery({
    variables: { id: sizeChartId! },
    pause: !sizeChartId,
  });

  const sizeCharts =
    useProductSizeChart && sizeChartId
      ? productSizeChartQuery.data?.sizeCharts
      : categorySizeChartQuery.data?.categoryById.sizeCharts;

  const bodyMeasurementIds = [
    'body-measurement-message-top',
    'body-measurement-message-bottom',
  ];
  const japanChartExclusions = [
    'size-chart-global-template-copied',
    ...bodyMeasurementIds,
  ];

  return (
    <Modal
      variant="dark"
      className="w-full max-w-5xl p-0 lg:p-4"
      onClickOutside={onClickOutside}
    >
      <div data-test-id="size-guide-modal" className="p-6">
        {categorySizeChartQuery.fetching || productSizeChartQuery.fetching ? (
          <LoadingSpinner className="w-40 h-40 my-40 mx-auto" />
        ) : (
          <div className="prose-sm md:(prose max-w-full) lg:prose-lg overflow-x-scroll hide-scrollbar">
            <div className="flex items-center border-b pb-3 justify-between">
              <span className="uppercase text-sm font-bold tracking-wider text-puma-black">
                {t('sizeGuide')}
              </span>
              <CloseButton
                size="sm"
                data-test-id="close-btn"
                onClick={onClickOutside}
              />
            </div>
            {sizeCharts?.map(sizeChart => {
              const exclusions = useDefaultJapanChart
                ? japanChartExclusions
                : bodyMeasurementIds;

              if (!exclusions.includes(sizeChart.id)) {
                return (
                  <SafeHtml
                    key={sizeChart.id}
                    html={sizeChart.body}
                    className={tw(sizeGuideStyles)}
                  />
                );
              }
            })}
            {productMeasurements && (
              <ProductMeasurementsTable
                measurementMessages={sizeCharts || []}
                productMeasurements={productMeasurements}
                productDivision={productDivision}
              />
            )}
            {!sizeCharts && !productMeasurements && (
              <div
                data-test-id="size-guide-no-sizing-data-found"
                className="font-bold text-xl"
              >
                No sizing data found
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
