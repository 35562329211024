import { useSiteConfig } from 'hooks/useSiteConfig';
import { StyliticsBundle } from 'ui/components/StyliticsWidget/types';
import { AnalyticsEvents, event } from 'utils/analytics';
import { Dimensions } from 'types/analyticTypes';

const MIN_BUNDLES = 3;

export type StyliticsWidgetArgs = {
  itemNumber: string;
  itemName: string;
};

export const useStyliticsWidget = ({
  itemNumber,
  itemName,
}: StyliticsWidgetArgs) => {
  const { locale } = useSiteConfig();
  const styliticsOptions = {
    api: {
      item_number: itemNumber,
      min: MIN_BUNDLES,
      max: 18,
    },
    customer: {
      locale,
    },
    display: {
      disableMnM: false,
      swipeableCarouselArrows: true,
    },
    responsive: [
      [220, { columns: 1 }],
      [655, { columns: 2 }],
      [895, { columns: 3 }],
    ],
  };

  const fireBundleTracking = (domlabel: string, outfitID: number) =>
    event(AnalyticsEvents.STYLITICS_INTERACTION, {
      domevent: 'Stylitics - Product Click',
      domlabel,
      [Dimensions.d27]: outfitID,
    });

  const fireBundleTrackingStylitics = (outfitID: number) =>
    event(AnalyticsEvents.PROMOTION_CLICK, {
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: outfitID,
              name: 'How To Wear It',
              creative: 'stylitics',
              position: '1',
              cta: 'View Details', // CTA Text
            },
          ],
        },
      },
    });

  const initShopTheLookElement = () => {
    const shopLookElement = document.createElement('span'); // create shop the look element
    shopLookElement.className = 'stylitics-shop-the-look-badge'; // add css class to the element

    const shopLookLabelElement = document.createElement('span'); // create shop the label element
    shopLookLabelElement.className = 'stylitics-shop-the-look-label'; // add css class to the label element
    shopLookLabelElement.textContent = 'Shop the Look';
    shopLookElement.appendChild(shopLookLabelElement);

    return shopLookElement;
  };

  const createShopTheLookElement = (bundleElement: Element) =>
    bundleElement.appendChild(initShopTheLookElement());

  const renderShopTheLook = (bundleElement: Element) =>
    bundleElement
      ?.querySelector('.stylitics-bundle-badge')
      ?.appendChild(createShopTheLookElement(bundleElement));

  const bundleTracking = ({
    element,
    trackerFn,
  }: {
    element: Element;
    trackerFn: () => void;
  }) => {
    const viewDetailsCta = element.querySelector(
      '.stylitics-view-item-details-cta'
    );
    viewDetailsCta?.addEventListener('click', trackerFn);

    const mainImage = element.querySelector('.stylitics-main-image');
    mainImage?.addEventListener('click', trackerFn);
  };

  const onBundle = ({
    element,
    bundle,
  }: {
    element;
    bundle: StyliticsBundle;
  }) => {
    let domlabel = 'Complete the Look';
    if (bundle['on-model-image']) {
      renderShopTheLook(element);
      domlabel = 'Complete the Look - Shop the Model';
    }

    bundleTracking({
      element,
      trackerFn: () => {
        fireBundleTracking(domlabel, bundle.id),
          fireBundleTrackingStylitics(bundle.id);
      },
    });
  };

  const onBundles = (data: { bundles?: StyliticsBundle[] }, callback) => {
    if (data.bundles && data.bundles.length >= MIN_BUNDLES) {
      //if data.bundles.length is >= 3 then the widget will load, and this code block will run
      //If 2 or fewer outfits are returned then the widget will hide.
      callback(true);
      // show the complete the look button on the pdp
      document
        ?.querySelector('#complete-the-look')
        ?.classList.remove('invisible');
    }
  };

  const onViewBundle = (data: { bundle: StyliticsBundle }) => {
    event(AnalyticsEvents.STYLITICS_INTERACTION, {
      domevent: 'Stylitics - Outfit Viewed',
      [Dimensions.d27]: data.bundle.id,
    });
    event(AnalyticsEvents.PROMOTION_VIEW, {
      ecommerce: {
        promoView: {
          promotions: [
            {
              id: data.bundle.id,
              name: 'How To Wear It',
              creative: 'stylitics',
              position: '1',
            },
          ],
        },
      },
    });
    event(AnalyticsEvents.GA4_CustomEvent, {
      event_name: AnalyticsEvents.GA4EC_Stylitics,
      event_params: {
        user_action: AnalyticsEvents.GA4_IMPRESSION,
        item_id_ep: itemNumber, // The ID of the item presented on the Product page on which a user interacts with the Stylitics feature
        item_name_ep: itemName, // The name of the item presented on the Product page on which a user interacts with the Stylitics feature
        stylitics_outfit_id: data.bundle.id,
        stylitics_product_id:
          data.bundle.primary_stylitics_item_id || undefined,
      },
    });
  };

  return {
    options: styliticsOptions,
    onBundle,
    onBundles,
    onViewBundle,
  };
};
