export const CustomizedBadge = ({ label }: { label: string }) => {
  const style = {
    background:
      'linear-gradient(90deg, #0EEBF9 0%, #946FBA 33%, #F2006C 66%, #F84D04 100%)',
  };
  return (
    <span
      data-test-id="customized-product-badge"
      className="flex-shrink-0 text-xs h-6 px-4 min-w-6 inline-flex text-neutral-0 items-center justify-center gap-1 font-bold uppercase"
      style={style}
    >
      {label}
    </span>
  );
};
