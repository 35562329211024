import { useEffect, useRef } from 'react';

import { AnalyticsEvents, event } from 'utils/analytics';
import { areDeepEqual } from 'utils/deepEqual';

import { usePageEventsContext } from './usePageEventsContext';

type ItemListViewProps = {
  itemListId?: string;
  itemListName?: string;
  itemIdEp?: string;
  itemNameEp?: string;
  items?: any[];
};

export const useItemListViewEvent = ({
  itemListId,
  itemListName,
  itemIdEp,
  itemNameEp,
  items,
}: ItemListViewProps) => {
  const previousItemsRef = useRef<Array<any>>([]);

  const { pageviewEventHasFired } = usePageEventsContext();

  useEffect(() => {
    const alreadyExecuted = areDeepEqual(previousItemsRef.current, items);

    if (
      !itemListId ||
      !itemListName ||
      !items?.length ||
      alreadyExecuted ||
      !pageviewEventHasFired
    ) {
      return;
    }

    event(AnalyticsEvents.GA4EC_ItemListView, {
      event_name: AnalyticsEvents.VIEW_ITEM_LIST,
      ecommerce: {
        item_list_id: itemListId,
        item_list_name: itemListName,
        item_id_ep: itemIdEp,
        item_name_ep: itemNameEp,
        items,
      },
    });
    previousItemsRef.current = items;
  }, [
    itemListId,
    itemListName,
    itemIdEp,
    itemNameEp,
    items,
    pageviewEventHasFired,
  ]);
};
